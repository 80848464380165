import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from "react-router-dom";

import './_dashboard_sidebar.scss'
import PropTypes from 'prop-types'
import CheckBlue from '../../../assets/png/icon/icon_check_blue_64.png';

export default function DashboardSidebar(props) {
  const { data, selectedService, modular } = props
  const [expandMoreOptionState, setExpandMoreOptionState] = useState(false)
  const [ userData, setUserData ] = useState({})
  const [moreOptionState, setMoreOptionState] = useState([])
  const history = useHistory();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))
    setUserData(userDetails);
    if(modular) {

    }
    else {
      let newData = []
      newData.push({
        id: 0,
        title: 'Alle',
        fileName:'',
        isEnable: true
      });
      if (data !== undefined && data !== null) {
        if (data.userRole === 'Architect/Builder') {
          data.data.forEach((item) => {
            //generate four digit id
            let buildingIdNumber = 4 - item.id.toString().length;
            let generateNumber = "";
            for (let i = 0; i < buildingIdNumber; i++) {
              generateNumber = generateNumber + "0"
            }

            if (item.title !== null && item.title !== undefined) {
              newData.push({
                id: item.id,
                title: generateNumber+""+item.id+"-"+item.fileName,
                isEnable: false
              });
            }
          })
        }
        else{
            data.forEach((item) => {
              if (item.title !== null && item.title !== undefined) {
                newData.push({
                  id: item.serviceId,
                  title: item.title,
                  isEnable: false
                });
              }
            })
          }
        setMoreOptionState(newData)
        selectedService(newData)
      } else setMoreOptionState([])
    }
  }, [data])

  const handleExpandOption = () => {
    if (expandMoreOptionState === true) setExpandMoreOptionState(false)
    else setExpandMoreOptionState(true)
  }

  const handleSelectedService = (serviceId) => {
    const prevState = [...moreOptionState]
    if (serviceId === 0) {
      const serviceObj = prevState.find(a => a.id === serviceId)
      if (serviceObj !== undefined) {
        serviceObj.isEnable = !(serviceObj.isEnable)
      }
      prevState.forEach(item => {
        if (item.id > 0) {
          item.isEnable = false
        }
      })
    }
    else {
      const serviceObj = prevState.find(a => a.id === serviceId)
      if (serviceObj !== undefined) {
        serviceObj.isEnable = !(serviceObj.isEnable)
      }
      const alleServiceObj = prevState.find(a => a.id === 0)
      if (alleServiceObj !== undefined && alleServiceObj.isEnable === true)
        alleServiceObj.isEnable = false
    }
    selectedService(prevState)
    setMoreOptionState(prevState)
  }

  return (
    <Grid
      container
      className="dashboard-sidebar-main-container font-color-semi-dark-grey overflow-hidden"
      style = {{width: modular ? '250px' : '100%', position: modular ? 'fixed' : 'relative'}}
    >
      <Grid container item xs={12} className="height-disable">
        <Grid container className="margin-top-20">
          <div className="flex-row horizontal-center width-auto vertical-center cursor-pointer" onClick = {() => { history.push('/dashboard') }} >
            <img
              src={require('../../../assets/png/Keuringsfirma_logo_pos_RGB.png')}
              alt="logo color"
              className="dashboard-sidebar-logo object-fit-content"
            />
            {/*<div className = "flex-row">*/}
            {/*  <h1 className="vietnam-bold font-size-20 font-color-navy-blue-faded margin-left-10">*/}
            {/*    Docu*/}
            {/*  </h1>*/}
            {/*  <h1 className="vietnam-regular font-size-20 font-color-navy-blue margin-top-10">*/}
            {/*    Build*/}
            {/*  </h1>*/}
            {/*</div>*/}
          </div>
        </Grid>

        <Grid
          container
          className="margin-top-25 dashboard-sidebar-link cursor-pointer"
        >
          <Grid item xs={2} sm={1} md={1} lg={2}></Grid>
          <Grid item xs={6} sm={6} md={8} lg={8}>
            <div className = "flex-row" onClick = {() => { history.push('/dashboard') }}>
              <img src = {require('../../../assets/svg/icon/dashboard.svg')} className = "dashboard-sidebar-icon" alt = "dashboard" />
              <span className="vietnam-semibold font-size-14 margin-top-2 margin-left-18" >DASHBOARD</span>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className="dashboard-sidebar-link cursor-pointer"
        >
          <Grid item xs={2} sm={1} md={1} lg={2}></Grid>
            <div className = "flex-row" onClick = {() => { history.push('/berichten') }}>
                <img src = {require('../../../assets/svg/icon/mail.svg')} className = "dashboard-sidebar-icon" alt = "mail" />
                <span className="vietnam-semibold font-size-14 margin-top-2 margin-left-18">BERICHTEN</span>
              </div>
        </Grid>
        <Grid
          container
          className="dashboard-sidebar-link cursor-pointer"
        >
          <Grid container item xs={12}>
            <Grid item xs={2} sm={1} md={1} lg={2}></Grid>
            <Grid item xs={6} sm={6} md={8} lg={8}>
                <div className = "flex-row" onClick = {() => { history.push('/documenten') }}>
                  <img src = {require('../../../assets/svg/icon/document.svg')} className = "dashboard-sidebar-icon" alt = "document" />
                  <span className="vietnam-semibold font-size-14 margin-top-2 margin-left-18">DOCUMENTEN</span>
                </div>
            </Grid>
          </Grid>
        </Grid>
        {userData.roleId !== 6 && (
          <>
            <Grid
              container
              className="dashboard-sidebar-link cursor-pointer"
            >
              <div className = "flex-row width-auto" onClick = {handleExpandOption}>
                <Grid container item xs={12} alignItems = "center">
                  <Grid item xs={2} sm={1} md={1} lg={2}></Grid>
                  <Grid item xs={6} sm={6} md={8} lg={8}>
                      <div className = "flex-row">
                        <img src = {require('../../../assets/svg/icon/info.svg')} className = "dashboard-sidebar-icon" alt = "info" />
                        <span className="vietnam-semibold font-size-14 margin-top-2 margin-left-18 uppercase" onClick = {() => { history.push('/dashboard') }}>{userData.roleId && userData.roleId  === 2 ? 'Dossiers' : userData.roleId === 1 ? 'Diensten' : 'Bedrijf'}</span>
                      </div>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} lg={2}>
                    <img src = {require('../../../assets/svg/icon/down_arrow.svg')} alt = "arrow down" className = "dashboard-sidebar-arrow animatable-400 ease-in-out-quart" style = {{transform: expandMoreOptionState ? 'rotate(0deg)' : 'rotate(-90deg)'}} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {!modular && moreOptionState !== undefined &&
            expandMoreOptionState === true &&
            moreOptionState.length > 0 ? (
              <div className='dashboard-sidebar-building-height overflow-y-scroll'>
                {moreOptionState.map((option) => {
                  return (
                    <Grid
                      container
                      className="dashboard-sidebar-link cursor-pointer"
                      key={option.id}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={3} sm={1} md={1} lg={2}></Grid>
                          <Grid item xs={6} sm={6} md={8} lg={8}>
                            <div className = "flex-row vertical-center">
                              <img alt = 'check' src={CheckBlue} className="dashboard-sidebar-check-icon animatable-400 ease-in-out-quart" style = {{opacity: option.isEnable ? 1 : 0, transform: option.isEnable ? 'translateX(0px)' : 'translateX(10px)'}}/>
                              <span className="vietnam-regular margin-left-25 cursor-pointer"
                                onClick={() => handleSelectedService(option.id)}>
                                {`${option.title}`}
                              </span>
                            </div>
                          </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

DashboardSidebar.propTypes = {
  data: PropTypes.object,
  setSelectedTab: PropTypes.func,
  modular: PropTypes.bool,
}

DashboardSidebar.defaultProps = {
  data: {},
  setSelectedTab: () => {},
  modular: false
}
